<template>
    <div style="background:#fff;">
        <a-card title="团队红包奖励" style="width: 100%; min-height: 100%" :bordered="false">

            <span>
                <label style="">有效期：</label>
                <span>{{TermOfValidity}}</span>
                <!-- <a-input-number style="margin:0 10px" v-model="TermOfValidity" :disabled="disabled" />天 -->
            </span>

            <span style="margin-left:30px">
                <!-- <label>升级奖励金额：</label>
        <a-input-number style="margin:0 10px" v-model="UpgradeRewardMoney" :disabled="disabled" /> -->
            </span>
            <span>
                <label>充值金额：</label>
                <a-input-number style="margin:0 10px" v-model="RechargeMoney" :disabled="disabled" />
            </span><br />
            <label>激励类型：</label>
            <div class="l_task_drive_create_ranking_item" v-for="(item,index) in IntegralRule" :key="index" :disabled="disabled" style="margin-bottom:20px;margin-top:10px">
                <span style="float:left" :disabled="disabled">{{item.IntegralType}}:</span>
                <div>
                    <a-input-number style="margin:0 10px" v-model="item.IntegralNum" :disabled="disabled" />
                    <span>分</span>
                </div>
            </div>
            <label>排名奖励：</label>
            <div class="l_task_drive_create_ranking_item" v-for="(item,index) in RankingList" :key="index" :disabled="disabled" style="margin-bottom:20px;margin-top:10px">
                <span style="float:left" :disabled="disabled">第{{item.RankingNum}}名：</span>
                <div>
                    <a-input-number style="margin:0 10px" v-model="item.RewardMoney" :disabled="disabled" />
                    <span>元</span>
                </div>
            </div>


        </a-card>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "",
        data() {
            return {
                disabled: true,
                tableData: [],
                RankingList: [],
                RedEnvelopesNum: 0,
                OrRewardsType: "",
                RechargeMoney: 0,
                TermOfValidity: "",
                UpgradeRewardMoney: 0,
                PromotionList: [
                    { key: 0, value: "拉新" },
                    { key: 1, value: "购买" },
                    { key: 2, value: "领券" },
                    { key: 3, value: "阅读" }
                ],

                PromotionName: "拉新",
                IntegralRule: [],//积分规则
            };
        },

        props: {//组件属性
        },
        methods: {//方法
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Promote/GetTeamMotivationData",
                    data: {
                        TeamMotivationID: self.ID,
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data;
                        self.UpgradeRewardMoney = data.data.UpgradeRewardMoney;
                        self.RechargeMoney = data.data.RechargeMoney;

                        var BeginDates = util.TimeStamptoDateTime(data.data.StartTime, 'yyyy-MM-dd');
                        var EndDates = util.TimeStamptoDateTime(data.data.EndTime, 'yyyy-MM-dd hh:mm:ss');
                        self.TermOfValidity = BeginDates + "至" + EndDates;

                    }
                };
                http.Post(op);
            },

            getRankingList: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Promote/GetRankingData",
                    data: {
                        TeamMotivationID: self.ID,
                    },
                    OnSuccess: function (data) {
                        self.RankingList = data.data;
                    }
                };
                http.Post(op);
            },
            //加载积分规则数据
            GetIntegralRule() {
                var self = this;
                var op = {
                    url: "/MarketingModule/Promote/GetIntegralRuleList",
                    data: {
                        TeamMotivationID: self.ID,
                    },
                    OnSuccess: function (data) {
                        if (data.data.Result) {
                            var obj = data.data.Data;
                            for (var i = 0; i < obj.length; i++) {
                                if (obj[i].IntegralType == 2)
                                    obj[i].IntegralType = "拉新";
                                else if (obj[i].IntegralType == 1)
                                    obj[i].IntegralType = "阅读";
                                else if (obj[i].IntegralType == 4)
                                    obj[i].IntegralType = "咨询";
                                else if (obj[i].IntegralType == 5)
                                    obj[i].IntegralType = "购买";
                            }
                            self.IntegralRule = obj;
                            //console.log("gfdjsg001:", self.IntegralRule);

                        } else {
                            alert(data.data.Message);
                            //console.log("gfdjsg002:", self.IntegralRule);
                        }
                    }
                };
                http.Post(op);
            },
        },
        computed: {//计算属性


        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            var self = this;
            self.ID = self.$route.query.ID;
            this.getTableData();
            this.getRankingList();
            //加载积分规则数据
            this.GetIntegralRule();
        },

        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };


</script>